import { ElMessage } from "element-plus";
// import IMG_OFFICE_PNG from "../../assets/office.png";
import IMG_OFFICE_PNG from "../../assets/office.png";

// 数据模型；
const USER_MODEL = {
  name: "userpassword",
  fields: [
    { key: "username", name: "用户名", type: "Text", defaultValue: "" },
    { key: "password", name: "密码", type: "Text", contentType: "password", defaultValue: "" },
  ],
};

// 数据视图元数据；
const USER_METADATA = {
  name: "userpassword",
  title: "用户登录信息",
  fields: [
    { key: "username", name: "用户名", dataKey: "username" },
    { key: "password", name: "密码", dataKey: "password" },
  ],
};

const USER_VIEW = {
  model: USER_MODEL,
  data: {
    username: "",
    password: "",
  },
};


/**
 * 登录页面组件；
 */
export default {
  name: "login-page",

  data() {
    return {
      metadata: USER_METADATA,
      user: USER_VIEW,

      rememberMe: false,

      img: IMG_OFFICE_PNG,
    };
  },

  inject: ["serviceContext"],

  created() {
    let authService = this.serviceContext.createService({ name: "authService", deepDecoding: true });

    authService.createAction({
      name: "login",
      path: "/login",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "No-Redirect": "true",
      },

      formParams: {
        username: { required: true },
        password: { required: true },
      },
    });
  },

  methods: {
    handleLoginClick() {
      // const loginAction = this.serviceContext.services.authService.http.POST["/login"];
      const loginAction = this.serviceContext.services.authService.actions.login;

      this.$refs["form-login"].submitToService(loginAction, {
        responseHandler: function (response, requestData) {
          ElMessage({
            showClose: true,
            message: "登录成功！",
            type: "success",
          });

          if (response.code == 10302) {
            //跳转；
            let redirectURL = response.data;
            window.location.replace(redirectURL);
          }

          return requestData;
        },

        errorHandler: function (errorResponse) {
          console.error("登录失败！-- error response=", errorResponse);
          ElMessage({
            showClose: true,
            message: "登录失败！ --" + errorResponse.message,
            type: "error",
          });
        },
      });

    },
  },
};
