<!DOCTYPE html>
<div class="container">
    <el-col :span="13"  class="topic-panel">
        <el-image :src="img" fit="scale-down" style="width: 400px;"></el-image>
    </el-col>
    <el-col :span="7"  class="login-form">
        <div style="width: 300px;">
            <el-row class="flex-center-h">
                <h1>登&nbsp;&nbsp;&nbsp;&nbsp;录</h1>
            </el-row>
            <el-row>
                <x-form 
                    ref="form-login" 
                    style="width: 100%;"
                    label-layout="placeholder" 
                    row-gap="8px" 
                    :metadata="metadata" 
                    :data-binding="user" 
                    writeonly
                    submit-on-enter 
                    
                    @enter-submit="handleLoginClick">
                </x-form>
            </el-row>
    
            <el-row class="rememberme" :style="'font-size: var(--el-font-size-base)'">
                <el-switch style="margin-right: 4px;" v-model="rememberMe" />
                <label>记住帐号</label> 
            </el-row>
    
            <el-row style="padding: 16px 24px;">
                <el-col :span="24">
                    <el-button style="width:100%" type="primary" @click="handleLoginClick">登录</el-button>
                </el-col>
            </el-row>
        </div>
    </el-col>
</div>